import { GeosearchResponse } from "~/bff/transport/Geosearch";
import { Coordinate } from "~/bff/types/Coordinate";
import { Store } from "~/bff/types/Store";
import {
  GEOLOCATION_ERROR_GETTING_YOUR_LOCATION,
  GEOLOCATION_ERROR_IS_NOT_AVAILABLE,
  IS_GEOLOCATION_AVAILABLE,
} from "~/components/store-locator-page/components/search-field/constants";
import { DEFAULT_STORES_RADIUS } from "~/components/store-locator-page/constants";
import { getPrediction } from "~/components/store-selector/components/search-field/helpers";
import { LOCALES } from "~/constants/i18n";
import { GET_STORES_QUERY } from "~/graphql/queries/getStores";
import { executeGrapQlQuery } from "~/helpers/execute-graphql-query";
import { Nullable } from "~/types/general.types";
import { Logger } from "~/utils/logger";

export interface Result {
  place?: Coordinate;
  stores: Nullable<Store[]>;
}

export const findByAddress = async (
  locale: LOCALES,
  region: string,
  address: string,
  offset = 0,
  limit = 10,
): Promise<Nullable<Result>> => {
  return new Promise<Nullable<Result>>((resolve, reject) => {
    const trimmed = address.trim();
    if (trimmed === "") {
      resolve({
        stores: [],
      });
      return;
    }
    const autocomplete = new google.maps.places.AutocompleteService();
    autocomplete.getPlacePredictions({ input: address }, (predictions, status) => {
      if (
        status !== google.maps.places.PlacesServiceStatus.OK ||
        !predictions?.length
      ) {
        resolve({ stores: [] });
        return;
      }
      const prediction = getPrediction(predictions, address);
      new google.maps.Geocoder().geocode(
        {
          placeId: prediction?.place_id,
          region: region,
        },
        async (
          results: google.maps.GeocoderResult[] | null,
          status: google.maps.GeocoderStatus,
        ) => {
          if (
            status !== google.maps.GeocoderStatus.OK &&
            status !== google.maps.GeocoderStatus.ZERO_RESULTS
          ) {
            reject(new Error("Something went wrong"));
            return;
          }
          if (!results || results.length === 0) {
            resolve({
              stores: [],
            });
            return;
          }
          const result = results[0];
          const {
            geometry: {
              location: { lat, lng },
            },
          } = result;
          const latitude = lat();
          const longitude = lng();
          try {
            const response = await executeGrapQlQuery<GeosearchResponse>(locale, {
              query: GET_STORES_QUERY,
              variables: {
                locale,
                latitude: latitude,
                longitude: longitude,
                radius: DEFAULT_STORES_RADIUS,
                offset: offset,
                limit: limit,
              },
            });
            const stores = response?.data?.geosearch?.stores?.filter(
              (store): store is Store => store !== null,
            );
            if (stores) {
              resolve({
                place: {
                  latitude: latitude,
                  longitude: longitude,
                },
                stores,
              });
            }
          } catch (error) {
            reject(error instanceof Error ? error : new Error(String(error)));
          }
        },
      );
    });
  });
};

export const findByGeolocation = async (
  locale: LOCALES,
  region?: string,
  offset = 0,
  limit = 10,
): Promise<Nullable<Result>> => {
  return new Promise<Nullable<Result>>((resolve, reject) => {
    if (!IS_GEOLOCATION_AVAILABLE) {
      reject(new Error(GEOLOCATION_ERROR_IS_NOT_AVAILABLE));
      return;
    }
    navigator.geolocation.getCurrentPosition(
      async (position: GeolocationPosition) => {
        const {
          coords: { latitude, longitude },
        } = position;
        try {
          const result = await executeGrapQlQuery<GeosearchResponse>(locale, {
            query: GET_STORES_QUERY,
            variables: {
              locale,
              latitude: latitude,
              longitude: longitude,
              radius: DEFAULT_STORES_RADIUS,
              offset: offset,
              limit: limit,
            },
          });
          const stores = result?.data?.geosearch?.stores?.filter(
            (store): store is Store => store !== null,
          );
          if (stores) {
            resolve({
              place: {
                latitude: latitude,
                longitude: longitude,
              },
              stores,
            });
          }
        } catch (error) {
          const errorMessage =
            error instanceof Error ? error.message : String(error);
          Logger.getLogger().error(errorMessage);
          reject(error instanceof Error ? error : new Error(String(error)));
        }
      },
      (error: GeolocationPositionError) => {
        Logger.getLogger().error("Something went wrong", error);
        reject(new Error(GEOLOCATION_ERROR_GETTING_YOUR_LOCATION));
      },
    );
  });
};

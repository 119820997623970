"use client";

import React, { useCallback, useMemo, useState, useRef, useEffect } from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { useSearchParams } from "next/navigation";
import type SwiperCore from "swiper";
import { v4 as uuidv4 } from "uuid";

import { Store } from "~/bff/types/Store";
import { StoreLocatorPageTranslations } from "~/bff/types/StoreLocatorPageTranslations";
import { MapView } from "~/components/map/component";
import { getCenter, getZoom } from "~/components/map/helpers";
import { Params } from "~/constants/request";
import { Country } from "~/helpers/country/types";
import { importComponentByName } from "~/helpers/import-component-by-name";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { useWindowState } from "~/hooks/use-window-state/use-window-state";
import { Swiper, SwiperSlide } from "~/lib/swiper/component";
import { Nullable, Optional } from "~/types/general.types";

import { DEFAULT_ZOOM, MY_LOCATION_ZOOM } from "../../constants";
import { NoResults } from "../no-results/component";
import { StoreCard } from "../store-card/component";

import { STORES_LIST_CLASSES, StoresView } from "./constants";
import { StoresListContainer } from "./styled";

import "swiper/css/bundle";

const MapWrapper = dynamic(async () =>
  importComponentByName(import("~/components/map-wrapper/component"), "MapWrapper"),
);

export interface StoresListProps {
  country?: Country;
  stores?: Nullable<Store>[] | undefined;
  selected?: Store;
  onStoreSelect?: (store?: Store | undefined) => void;
  onRedirectToStoreDetailsPage?: (store?: Store | undefined) => void;
  translations?: Nullable<StoreLocatorPageTranslations> | undefined;
}

export const StoresList: React.FC<StoresListProps> = ({
  country,
  stores,
  selected: selectedStore,
  onStoreSelect,
  onRedirectToStoreDetailsPage,
  translations,
}) => {
  const theme = useTheme();

  const searchParams = useSearchParams();
  const latParam = searchParams.get(Params.LATITUDE);
  const lngParam = searchParams.get(Params.LONGITUDE);
  const zoomParam = searchParams.get(Params.ZOOM);
  const queryParam = searchParams.get(Params.STORE_QUERY);
  const cityParam = searchParams.get(Params.CITY);

  const rootRef = useRef<Optional<HTMLDivElement>>();
  const carouselRef = useRef<Optional<HTMLDivElement>>();
  const [token, setToken] = useState<string>(() => uuidv4());
  const [selected, setSelected] = useState<Optional<Store>>();
  const [mode, setMode] = useState<StoresView>(StoresView.LIST);
  const [carouselHeight, setCarouselHeight] = useState<Optional<number>>();
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();
  const coordinates = useMemo(() => {
    const lat = country?.coordinates?.latitude;
    const lng = country?.coordinates?.longitude;
    if (latParam && lngParam) {
      return { lat: Number(latParam), lng: Number(lngParam) };
    } else if (cityParam) {
      const center = getCenter(stores);
      return center ? { lat: center.lat, lng: center.lng } : null;
    } else {
      return lat !== undefined && lng !== undefined ? { lat, lng } : null;
    }
  }, [country, stores, latParam, lngParam, cityParam]);
  const zoom = useMemo(() => {
    if (zoomParam && /^\d+$/.test(zoomParam as string)) {
      return Number(zoomParam);
    }
    if (stores && stores.length !== 0) {
      return getZoom(theme, stores);
    }
    if (latParam && lngParam && !queryParam) {
      return MY_LOCATION_ZOOM;
    }
    return country?.zoom ?? DEFAULT_ZOOM;
  }, [zoomParam, latParam, lngParam, queryParam, country, stores, theme]);
  const isListMode = useMemo(() => mode === StoresView.LIST, [mode]);
  const isTabsAvailable = useMemo(
    () => !!stores?.length && !!queryParam,
    [stores, queryParam],
  );
  const isHeaderAvailable = useMemo(() => !!queryParam, [queryParam]);
  const { token: windowToken } = useWindowState();

  const selectedRef = useRef<Optional<Store>>(selected);
  selectedRef.current = selected;

  const handleStoreSelect = useCallback(
    (store: Store | undefined) => {
      setSelected(store);
      if (onStoreSelect) {
        onStoreSelect(store);
      }
    },
    [onStoreSelect],
  );

  const handleViewChange = useCallback(
    (event: React.SyntheticEvent, mode: StoresView) => setMode(mode),
    [],
  );

  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (windowToken && carouselRef.current && mode === StoresView.MAP) {
      const elements = Array.from(
        carouselRef.current.querySelectorAll(".swiper-slide > div > div"),
      ) as HTMLDivElement[];
      const carouselHeight = Math.max(
        ...elements.map((element) => element.scrollHeight),
      );
      setCarouselHeight(carouselHeight);
    }
  }, [windowToken, mode]);

  useEffect(() => {
    if (selectedRef.current !== selectedStore) {
      setSelected(selectedStore);
      if (selectedStore && isListMode) {
        const element = rootRef.current?.querySelector(
          `[data-store-id="${selectedStore.id}"]`,
        ) as HTMLElement;
        rootRef.current?.scrollTo({ top: element.offsetTop });
      }
    }
  }, [selectedStore, isListMode]);

  useEffect(() => {
    if (stores) {
      setToken(uuidv4());
    }
  }, [stores]);

  useEffect(() => {
    if (swiperInstance) {
      const slidesIdsList = swiperInstance.slides?.map((el: Element) =>
        el.getAttribute("data-swiper-slide-index"),
      );
      const targetStoreIndex = slidesIdsList?.findIndex(
        (slideId: string | null) => slideId === selected?.id,
      );
      swiperInstance.slideTo(targetStoreIndex, 300);
    }
  }, [selected?.id, swiperInstance]);

  return (
    <StoresListContainer
      isHeaderAvailable={isHeaderAvailable}
      carouselHeight={carouselHeight}
      isEmpty={!stores || stores.length === 0}
      {...getTestAutomationProps("stores-list")}
    >
      {stores?.length ? (
        <>
          {isTabsAvailable && (
            <Tabs
              value={mode}
              onChange={handleViewChange}
              className={STORES_LIST_CLASSES.tabs}
              {...getTestAutomationProps("tabs")}
            >
              <Tab
                value={StoresView.LIST}
                className={STORES_LIST_CLASSES.tab}
                label={translations?.listViewTitle}
                {...getTestAutomationProps("tab-list")}
              />
              <Tab
                value={StoresView.MAP}
                className={STORES_LIST_CLASSES.tab}
                label={translations?.mapTitle}
                {...getTestAutomationProps("tab-map")}
              />
            </Tabs>
          )}
          {isListMode ? (
            <>
              {isHeaderAvailable ? (
                <Typography component="h5" className={STORES_LIST_CLASSES.header}>
                  {translations?.radiusDescription}
                </Typography>
              ) : undefined}
              {stores.map((store, index, array) => {
                return (
                  <Box key={index} data-store-id={store?.id}>
                    <StoreCard
                      selected={selected === store}
                      onStoreSelect={handleStoreSelect}
                      onRedirectToStoreDetailsPage={onRedirectToStoreDetailsPage}
                      country={country}
                      store={store}
                      translations={translations ?? {}}
                    />
                    {array.length - 1 !== index ? (
                      <Divider className={STORES_LIST_CLASSES.divider} />
                    ) : undefined}
                  </Box>
                );
              })}
            </>
          ) : (
            <>
              <MapWrapper>
                <MapView
                  zoom={zoom}
                  center={coordinates}
                  selected={selected}
                  onStoreSelect={handleStoreSelect}
                  stores={stores}
                  gestureHandling="none"
                  disableDefaultUI={true}
                  zoomControl={false}
                  clickableIcons={false}
                  className={STORES_LIST_CLASSES.map}
                />
              </MapWrapper>
              <Box
                className={STORES_LIST_CLASSES.carousel}
                {...getTestAutomationProps("stores-carousel")}
              >
                <Swiper
                  onSwiper={setSwiperInstance}
                  key={token}
                  spaceBetween={16}
                  slidesPerView={1.25}
                  slidesOffsetBefore={isMobileView ? 16 : 32}
                  breakpoints={{
                    [theme.breakpoints.values.sm]: {
                      spaceBetween: 24,
                      slidesPerView: 2.5,
                    },
                  }}
                >
                  {stores.map((store, index) => {
                    return (
                      <SwiperSlide key={index} virtualIndex={Number(store?.id)}>
                        <Paper
                          variant="outlined"
                          className={clsx(STORES_LIST_CLASSES.slide, {
                            [STORES_LIST_CLASSES.slideSelected]: selected === store,
                          })}
                        >
                          <StoreCard
                            selected={selected === store}
                            onStoreSelect={handleStoreSelect}
                            country={country}
                            store={store}
                            translations={translations ?? {}}
                            asSlide
                            {...getTestAutomationProps("store-card")}
                          />
                        </Paper>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Box>
            </>
          )}
        </>
      ) : (
        <NoResults
          noSearchResultsTitle={translations?.noSearchResultsTitle ?? ""}
          noSearchResultsSubtitle={translations?.noSearchResultsSubtitle ?? ""}
        />
      )}
    </StoresListContainer>
  );
};
